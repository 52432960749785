.OlympiadGames-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin-top: 50px;
    margin-bottom: 50px;
    background: transparent;
    border-radius: 15px;
    background: url('./bgimg/sports-bg.png');
  }
  
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .header h1 {
    font-size: 2em;
    color: #06036c;
  }
  
  .welcome-section, .events-section, .prizes-section, .accommodation-section, .rules-section, .committee-section, .sponsors-section, .sponsorship-section, .info-section {
    margin-bottom: 40px;
  }
  
  .OlympiadGames-container h2 {
    font-size: 1.8em;
    margin-bottom: 15px;
    color: #06036c;
    border-bottom: 2px solid #06036c;
    padding-bottom: 5px;
  }
  
  .event-detail, .event {
    margin-bottom: 20px;
  }
  
  .OlympiadGames-container h3 {
    font-size: 1.6em;
    color: #06036c;
  }
  
  .OlympiadGames-container h4 {
    font-size: 1.4em;
    color: #06036c;
  }
  
  .OlympiadGames-container ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .OlympiadGames-container ul ul {
    list-style-type: circle;
    margin-left: 20px;
  }
  
  .OlympiadGames-container p {
    font-size: 1.1em;
  }
  
  .OlympiadGames-container a {
    color: #06036c;
    text-decoration: none;
  }
  
  .OlympiadGames-container a:hover {
    text-decoration: underline;
  }
  

  @media all and (min-width: 320px) and (max-width: 480px) {
    .cult-img-div img{
      width: 100%;
      display: flex;
      margin: auto;
      border-radius: 15px;
    }

    .header h1 {
      font-size: 1.5em;
      /* color: #333; */
    }

  }