body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('https://img.freepik.com/free-photo/children-s-silhouettes-playing-sunset_1150-456.jpg?w=996&t=st=1680804226~exp=1680804826~hmac=2ce77b0a208795c61af0dc693618acea6ef44573480f4e74a079a07908d81545'); */
  /* background-repeat: no-repeat; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
