/* enrollment */
.culturalfest-form {
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  margin: 50px auto;
  background: linear-gradient(to right, #fc17fa, #004AAD);
  box-sizing: border-box; /* Ensure padding and border are included in width calculations */
}

.culturalfest-form label {
  display: block;
  margin-bottom: 10px;
}

.culturalfest-form input[type="text"],
.culturalfest-form input[type="date"],
.culturalfest-form input[type="tel"],
.culturalfest-form input[type="email"],
.culturalfest-form select,
.culturalfest-form textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  box-sizing: border-box; /* Ensure padding and border are included in width calculations */
}

.culturalfest-form textarea {
  height: 80px;
}

.culturalfest-gender-select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box; /* Ensure padding and border are included in width calculations */
}

.culturalfest-submit-button-container {
  text-align: center;
}

.culturalfest-enroll-form-button {
  background: #FF0000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.culturalfest-enroll-form-button:hover {
  background: #FF4500;
}

.culturalfest-heading-main-box {
  text-align: center;
  margin-bottom: 20px;
}

.culturalfest-heading-main-box h1 {
  font-size: 24px;
  color: #004AAD;
}

.culturalfest-heading-main-box h5 {
  font-size: 18px;
  color: #000;
}

.culturalfest-enroll-upi-div {
  text-align: center;
  margin-top: 20px;
}

.culturalfest-enroll-upi-img {
  width: 200px;
  height: 200px;
}

.culturalfest-enroll-upi-heading {
  font-size: 18px;
  font-weight: bold;
}

.culturalfest-enroll-upi-h3 {
  margin: 10px 0;
}

.culturalfest-enroll-upi-note {
  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
}

/* Media Queries */

/* Medium screens and up (tablets, 600px and above) */
@media screen and (max-width: 900px) {
  .culturalfest-form {
    width: 70%;
  }

  .culturalfest-form input[type="text"],
  .culturalfest-form input[type="date"],
  .culturalfest-form input[type="tel"],
  .culturalfest-form input[type="email"],
  .culturalfest-form select,
  .culturalfest-form textarea {
    font-size: 13px;
  }

  .culturalfest-heading-main-box h1 {
    font-size: 20px;
  }
}

/* Small screens (mobile, 600px and below) */
@media screen and (max-width: 600px) {
  .culturalfest-form {
    width: 90%;
    margin: 20px auto;
    padding: 15px;
  }

  .culturalfest-form input[type="text"],
  .culturalfest-form input[type="date"],
  .culturalfest-form input[type="tel"],
  .culturalfest-form input[type="email"],
  .culturalfest-form select,
  .culturalfest-form textarea {
    font-size: 12px;
    padding: 6px;
  }

  .culturalfest-heading-main-box h1 {
    font-size: 18px;
  }

  .culturalfest-enroll-upi-img {
    width: 150px;
    height: 150px;
  }

  .culturalfest-enroll-upi-heading {
    font-size: 16px;
  }

  .culturalfest-enroll-upi-h3 {
    font-size: 14px;
  }

  .culturalfest-enroll-form-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}



/* ------------------------------------------------- */

  .Enroll--container {
    margin: auto;
    text-align: center;
    margin-top: 120px;
    margin-bottom: 120px;
    padding: 20px; 
    /* width: 50%;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #ccc; */
}

.home-heading-sizes {
    text-align: center;
    font-size: 2em; /* Default heading size */
    margin-bottom: 20px; /* Added margin for spacing */
}

/* Styling for the link buttons */
.Enroll--container div {
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

/* Media queries for different screen sizes */

/* Large screens (desktops, 1200px and up) */
@media (min-width: 1200px) {
    .home-heading-sizes {
        font-size: 3em; /* Larger font size for headings */
    }
    .Enroll--container div {
        margin-top: 60px; /* More space between buttons */
    }
}

/* Medium screens (tablets, 768px to 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
    .home-heading-sizes {
        font-size: 2.5em; /* Adjust font size for headings */
    }
    .Enroll--container {
        padding: 40px; /* Increase padding for better layout */
    }
    .Enroll--container div {
        margin-top: 40px; /* Reduce margin between buttons */
    }
}

/* Small screens (phones, up to 767px) */
@media (max-width: 767px) {
    .home-heading-sizes {
        font-size: 2em; /* Smaller font size for headings */
    }
    .Enroll--container {
        margin-top: 60px; /* Reduce top and bottom margins */
        margin-bottom: 60px;
        padding: 20px; /* Adjust padding for smaller screens */
    }
    .Enroll--container div {
        margin-top: 30px; /* Less space between buttons */
    }
    .btn-hover.color-3 {
        width: 100%; /* Full width buttons on small screens */
        box-sizing: border-box; /* Ensure padding does not increase width */
    }
}

/* Extra small screens (phones, up to 480px) */
@media (max-width: 480px) {
    .home-heading-sizes {
        font-size: 1.5em; /* Further reduce font size for headings */
    }
    .Enroll--container {
        margin-top: 40px; /* Further reduce margins */
        margin-bottom: 40px;
    }
    .Enroll--container div {
        margin-top: 20px; /* Further reduce space between buttons */
    }
}
