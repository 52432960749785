


.CulturalFest-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin-top: 50px;
    margin-bottom: 50px;
    background: transparent;
    border-radius: 15px;
    background: url('./bgimg/cult-bg2.png');
    
  }

  .cult-img-div img{
    width: 35%;
    display: flex;
    margin: auto;
    border-radius: 15px;
  }
  
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .header h1 {
    font-size: 2em;
    color: #21207e;
  }
  
  .welcome-section {
    margin-bottom: 30px;
  }
  
  .welcome-section p {
    font-size: 1.2em;
    color: #555;
  }
  
  .events-section, .prizes-section, .rules-section, .committee-section, .sponsors-section, .sponsorship-section, .info-section {
    margin-bottom: 40px;
  }
  
  .CulturalFest-container h2 {
    font-size: 1.8em;
    margin-bottom: 15px;
    color: #444;
    border-bottom: 2px solid #21207e;
    padding-bottom: 5px;
  }
  
  .event h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #21207e;
  }
  
  .CulturalFest-container ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .CulturalFest-container ul ul {
    list-style-type: circle;
  }
  
  .CulturalFest-container a {
    color: #21207e;
    text-decoration: none;
  }
  
  .CulturalFest-container a:hover {
    text-decoration: underline;
  }
  
  .info-section p {
    font-size: 1.1em;
  }
  

  @media all and (min-width: 320px) and (max-width: 480px) {
    .cult-img-div img{
      width: 100%;
      display: flex;
      margin: auto;
      border-radius: 15px;
    }

    .header h1 {
      font-size: 16px;
      color: #21207e;
    }

  }