/*  import google fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,700;0,800;1,600;1,900&family=Montserrat:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Neonderthaw:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik%20Pixels:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oleo%20Script%20Swash%20Caps:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap");
@font-face {
  font-family: "Shade Blue - Retro Script Font";
  src: url("../fonts/ShadeBlue-RetroScriptFont.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  text-align: "justify";
  /* background-color: #000; */
  
}

body {
  /* font-family: 'Crimson Pro', serif; */
  background: rgb(165,165,165);
background: linear-gradient(90deg, rgba(165,165,165,1) 30%, rgba(235,235,235,1) 65%);

  text-align: "justify";
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'Neonderthaw'; */
  font-family: "Alegreya", serif;
  /* font-family: "Great Vibes"; */
  /* font-family: 'Pacifico'; */
  background-color: #e3e4e6;
  background-color: #fff !important;
}
html {
  scroll-behavior: smooth;
}

p {
  text-align: "justify";
}

#animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #fc17fa 0%,
    #004AAD 29%,
    #ff13ef 67%,
    #004AAD 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  /* font-size: 45px; */
  font-weight: 700;
  text-align: center;
  font-style: normal;
  font-family: "Oleo Script Swash Caps";
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.btn-hover {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 50px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  text-align: center;
  margin: auto;
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-3 {
  background-image: linear-gradient(
    to right,
    #fc17fa,
    #004AAD,
    #ff13ef,
    #004AAD
  );
  box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
  border-radius: 15px;
}

#heading-big {
  font-family: "Neonderthaw";
  font-weight: 600;
  background-image: -webkit-linear-gradient(270deg, #fc17fa 40%, #004AAD 60%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  font-family: "Oleo Script Swash Caps";
  font-style: normal;
  font-weight: 700;
}

.main-logo {
  width: 240px;
  height: auto;
}

.multy-boxShadow {
  box-shadow: 0px 1px 2px 0px rgba(0, 255, 255, 0.7), 2px 4px 20px 2px #fc17fa,
    0px 0px 8px 0px #fc17fa, 2px 4px 30px 2px #004AAD;
}

.MuiAppBar-colorPrimary {
  color: #000 !important;
  background-color: #fff !important;
}


/* ---------Home----------- */

.home-heading-sizes {
  font-size: 50px;
  margin-top: 20px;
}

/* -------slide show----------- */

.slideshow-main-div {
  margin: auto;
  position: relative;
  background-color: #fff;
  height: 400px;
  width: 100%;
  overflow: hidden;
}

.slideshow-image {
  width: 100%;
  height: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

/* home page  */


.css-480o17-MuiGrid-root {
  width: calc(80% + 32px) !important;
  margin: auto !important;

}

.events1-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  width: 60%;
  margin: auto;
  border-radius: 15px;
}

.events1-box {
  /* background-color: #f5f5f5; */
  background: transparent;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 15px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.events1-box img{
  width: 300px;
  border-radius: 15px;
}

.fest-heading {
  font-size: 1.5rem;
  margin: 10px 0;
}

.btn-hover {
  margin-top: 10px;
  border-radius: 10px;
}



@media (min-width: 320px) {
  .events1-container {
    width: 80%;
    
  }

  .events1-container button{
    width: 60%;
    margin: auto;
    font-size: 12px;
  }

  .events1-box img{
    width: 200px;
    border-radius: 15px;
  }

  .fest-heading {
    font-size: 1.0rem;
    margin: 10px 0;
  }

  .fest-p{
    font-size: 0.7rem;
  }
}

@media (min-width: 768px) {
  .events1-container {
    flex-direction: row;
  }

  .events1-box {
    flex: 1;
  }

  .events1-box img{
    width: 300px;
    border-radius: 15px;
  }
}




/*--------------- aboutus -----------*/

.aboutus {
  font-size: 72px;
  /* background: -webkit-linear-gradient(#fc17fa, #004AAD);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  /* font-family: "Alegreya", serif; */
  font-family: "Neonderthaw";
  color: #fc17fa;
  background-image: -webkit-linear-gradient(180deg, #fc17fa 76%, #004AAD 100%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;

  padding-left: 5px;
}

.about-grid-box {
  display: flex;
  gap: 35px;
  margin: auto;
  padding: 20px;
  height: auto;
}

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@500&display=swap');

/* :root {
    --background: #2C2C2C;
} */

/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;  
} */

body {
    /* background-color: var(--background);
    display: grid; */
    /* height: 100vh; */
    margin: 0px;
    font-size: 16px;
    font-family: 'Red Hat Display', sans-serif;
}

.container {
    margin: auto;
    margin-right: auto;
    margin-left: auto;
    justify-content: baseline;
    align-items: baseline;
    position: relative;
    width: 430px;
    height: 100%;
    border-radius: 20px !important;
    padding: auto !important;
    box-sizing: border-box !important;
    background: #ecf0f3 !important;
    box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white !important; 
} 


  
.profile-wrapper {
    width: 350px;
    height: 450px;
    position: relative;
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: 350px;
    height: 450px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 5px 18px rgba(0, 0, 0, 0.6);
    cursor: pointer;
    transition: 0.5s;
}
 
.profile .profile-image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
    z-index: 2;
    background-color: var(--background);
    transition: .5s;
}

.profile-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.profile:hover img {
    opacity: 0.4;
    transition: .5s;
}

.profile:hover .profile-image {
    transform: translateY(-104px);
    transition: all .9s;
}

.profile:hover .profile-bio {
    opacity: 0.9; 
}

.social-icons {
    position: absolute;
    display: flex;
    gap: 12px;
    z-index: 3;
    bottom: 164px;
}
  
.social-icons li {
    list-style: none;
}

.social-icons li a {
    position: relative;
    display: block;
    display: grid;
    place-items: center;
    background: #fff;
    font-size: 23px;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border: 1px solid #CDCDCD;
    border-radius: 4px;
    transition: .2s;
    transform: translateY(32px);
    opacity: 0;
}

.social-icons li a:hover {
    background: var(--background);
    transition: .2s;
}

.social-icons li a svg {
    color: var(--background);
    transition: .2s;
}

.social-icons li a:hover svg {
    color: white;
    transition: 0.8s;
    transform: rotateY(360deg);
}

.profile:hover .social-icons li a {
    opacity: 1; 
}

.profile:hover li:nth-child(1) a {
    transition-delay: 0.1s;
}

.profile:hover li:nth-child(2) a {
    transition-delay: 0.2s;
}

.profile:hover li:nth-child(3) a {
    transition-delay: 0.3s;
}

.profile:hover li:nth-child(4) a {
    transition-delay: 0.4s;
}

.profile-name {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 4;
    background: var(--background);
    width: 100%;
    height: 104px;
    padding: 16px;
    z-index: 1;
    color: #333;
    text-align: left;
}

.profile:hover .profile-name {
    background-color: #fff;
}


.profile-name h2 {
    margin-bottom: 8px;
}

/* 
.image-slider {
  width: 100%;
  height: auto;
}

.image-slider .slide img {
  width: 100%;
  height: auto;
  object-fit: cover;
} */

.MuiImageSlider-root {
  width: 100% !important;
  height: auto !important;
  background-color: red;
}

.MuiImageSlider-slide img {
  width: 100% !important;
  height: auto !important;
  object-fit: cover !important;
}

/* ---------Subscription Page------- */

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap"); */

/* input {
  caret-color: red;
} */

.container {
  /* margin: auto; */
  margin-right: auto;
  margin-left: auto;
  justify-content: baseline;
  align-items: baseline;
  position: relative;
  width: 500px;
  height: 100%;
  border-radius: 20px;
  padding: 50px;
  box-sizing: border-box;
  background: #ecf0f3;
  box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
}

.newsub .upi{
  margin: auto;
  text-align: center;
  justify-content: center;
  margin-left: 550px;
}

.brand-logo {
  height: 100px;
  width: 100px;
  background: url("https://i.ibb.co/XXv2tmK/web-logo-copy.png");
  background-repeat: no-repeat;
  margin: auto;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 7px 7px 10px #cbced1, -7px -7px 10px white;
}

.brand-title {
  margin-top: 10px;
  font-weight: 900;
  font-size: 1.8rem;
  color: #004AAD;
  letter-spacing: 1px;
  margin: auto;
  text-align: center;
}

.inputs {
  text-align: left;
  margin-top: 30px;
}

label,
input,
button {
  display: block;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}

label {
  margin-bottom: 4px;
}

label:nth-of-type(2) {
  margin-top: 12px;
}

input::placeholder {
  color: gray;
}

.inputs input {
  background: #ecf0f3;
  padding: 10px;
  padding-left: 20px;
  height: 50px;
  font-size: 14px;
  border-radius: 50px;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
  margin-bottom: 30px;
}

#subscriptio-btn {
  color: white;
  margin-top: 20px;
  background: #004AAD;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 900;
  box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
  transition: 0.5s;
}

#subscriptio-btn:hover {
  box-shadow: none;
}
/* portfolio */

.my-component {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@keyframes float1 {
  0% { transform: translate(0, 0); }
  50% { transform: translate(-10px, 0); }
  100% { transform: translate(0, 0); }
}

.triangle-float1 {
  animation: float1 5s infinite;
}

@keyframes float2 {
  0% { transform: translate(0, 0); }
  50% { transform: translate(-5px, -5px); }
  100% { transform: translate(0, 0); }
}

.triangle-float2 {
  animation: float2 4s infinite;
}

@keyframes float3 {
  0% { transform: translate(0, 0); }
  50% { transform: translate(0, -10px); }
  100% { transform: translate(0, 0); }
}

.triangle-float3 {
  animation: float3 6s infinite;
}

.glass-css-box{
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

#glass-css-box{
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);

}


.portfolio-page-headings {
  margin: auto;
  padding: 10px;
  text-align: center;
  font-size: 48px;
}


.most-profile {
  background-image: linear-gradient(
    180deg,
    hsl(301deg 100% 56%) 5%,
    hsl(295deg 97% 66%) 25%,
    hsl(287deg 96% 72%) 36%,
    hsl(274deg 96% 77%) 44%,
    hsl(255deg 96% 81%) 52%,
    hsl(229deg 96% 81%) 60%,
    hsl(210deg 98% 76%) 69%,
    hsl(197deg 98% 70%) 79%,
    hsl(189deg 99% 60%) 100%
  );
  color: #111;
}

.portfolio-below-main :hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


/* ---------------faq------------- */

.faq-mbox-1 {
  background-color: #004AAD;
  width: 50%;
  height: auto;
  margin: auto;
  padding: 10px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-mbox-2 {
  background-color: #f055ed;
  width: 50%;
  height: 400px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-mbox-2 img {
  width: 100%;
  height: 100%;
}

.faq-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-item h3 {
  margin-bottom: 8px;
}

.faq-item p {
  margin-top: 4px;
}

.faq-item em {
  font-style: normal;
  font-weight: bold;
}

/* -----------vision---------------- */

.vision-main-box {
  width: 100%;
  height: auto;
  /* border: 1px solid red; */
  display: flex;
}

.vision-main-box1 {
  width: 30%;
  height: auto;
  display: flex;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;

  padding: 40px;
  /* background-color: #fff; */
}

.vision-main-box2 {
  width: 70%;
  height: 650px;
  /* background-color: #f055ed; */
}

.vision-main-box2 img {
  width: 100%;
  height: 100%;
}

.vision-grid-box1 {
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #004AAD;
  background-image: linear-gradient(
    to right top,
    #004AAD,
    #004AAD,
    #9787ff,
    #f055ed,
    #fc17fa
  );
}

.css-1fh410u-MuiGrid-root > .MuiGrid-item {
  padding-left: 0px !important;
}

.css-xepata-MuiGrid-root > .MuiGrid-item {
  padding-left: 0px !important;
}

.css-1ld3b9g-MuiGrid-root > .MuiGrid-item {
  padding-left: 0px !important;
}

/* contact us */

/*  import google fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,700;0,800;1,600;1,900&family=Montserrat:wght@400;600&display=swap");

html {
  scroll-behavior: smooth;
}

/* -----------whatsapp------------ */

.icon-bar {
  position: fixed;
  top: 93%;
  left: 2%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  padding: 12px 14px;
  color: #fff;
  border-radius: 50px;
  font-size: x-large;
}
.whatsapp {
  background: #f055ed;
}

/*------- custom scroll bar --------*/

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
  background-image: linear-gradient(
    to right top,
    #004AAD,
    #004AAD,
    #9787ff,
    #f055ed,
    #fc17fa
  );
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  background-image: linear-gradient(
    to right top,
    #004AAD,
    #004AAD,
    #9787ff,
    #f055ed,
    #fc17fa
  );
}

.max-width {
  max-width: 1300px;
  padding: 0 80px;
  margin: auto;
}

.contact {
  font-family: "Poppins", sans-serif;
  width: 80%;
  height: auto;
  border-radius: 15px;
  margin: auto;
  padding: 20px;
  /* background-color: rgb(54,225,253);
  background-image: linear-gradient(to right top, #004AAD, #00d0ff, #004AAD, #9787ff, #fc17fa);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  
  background: #004AAD;
  background: -webkit-linear-gradient(to right, #fc17fa, #004AAD);
  background: linear-gradient(to right, #fc17fa, #004AAD);

  
/* opacity: 0.8; */

 /* background-color: #cccccc; */
}
.about-content,
.serv-content,
.contact .contact-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

/* contact section styling */

.contact .title::after {
  content: "Get in touch";
  font-family: "Alegreya", serif;
}
.contact .contact-content .column {
  width: calc(50% - 30px);
}
.contact .contact-content .text {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  height: auto;
}
.contact .contact-content .left p {
  text-align: justify;
  height: auto;
}
.contact .contact-content .left .icons {
  margin: 10px 0;
  height: auto;
}
.contact .contact-content .row {
  display: flex;
  height: 65px;
  align-items: center;
  height: auto;
  width: 100%;
}
.contact .contact-content .row .info {
  margin-left: 30px;
}
.contact .contact-content .row i {
  font-size: 25px;
  color: #f055ed;
}
.contact .contact-content .info .head {
  font-weight: 500;
  color: #fff;
}
.contact .contact-content .info .sub-title {
  color: #fff;
}
.contact .right form .fields {
  display: flex;
}
.contact .right form .field,
.contact .right form .fields .field {
  height: 45px;
  width: 100%;
  margin-bottom: 15px;
}
.contact .right form .textarea {
  height: 80px;
  width: 100%;
}
.contact .right form .name {
  margin-right: 10px;
}
.contact .right form .field input,
.contact .right form .textarea textarea {
  height: 100%;
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 6px;
  outline: none;
  padding: 0 15px;
  font-size: 17px;
  font-family: "Poppins", sans-serif;
  transition: all 0.3s ease;
}
.contact .right form .field input:focus,
.contact .right form .textarea textarea:focus {
  border-color: #b3b3b3;
}
.contact .right form .textarea textarea {
  padding-top: 10px;
  resize: none;
}
.contact .right form .button-area {
  display: flex;
  align-items: center;
}
.right form .button-area input {
  color: #fff;
  display: block;
  width: 160px !important;
  height: 45px;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  flex-wrap: nowrap;
  background: #f055ed;
  border: 2px solid #fff;
  transition: all 0.3s ease;
}
.right form .button-area input:hover {
  /* color: #f055ed; */
  background: none;
  background-color: #111;
}

/* ----------footer------------- */
.footer {
  bottom: 0;
  left: 0;
  right: 0;
}

:root {
  --background: #2C2C2C;
  --linkedin: #0A66C2;
  --youtube: #e52d27;
  --twitter: #1D9BF0;
}

.background {
  /* background-color: var(--background); */
  display: grid;
  place-items: center;
  /* height: 100vh; */
}

.social-media-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
}

.social-media-button {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  outline: 2px solid white;
  cursor: pointer;
  transition: all 0.3s;
  display: grid;
  place-items: center;
}

.social-media-button:hover {
  outline-offset: 3px;
  transition: all 0.3s;
}

.social-media-button img {
  transition: all 0.3s;
}

.social-media-button:hover img {
  transform: scale(1.15);
}

.social-media-button:hover:nth-child(1) {
  background:  radial-gradient(
      circle at 30% 107%, #fdf497 0%, 
      #fdf497 5%, #fd5949 45%,
      #d6249f 60%,#285AEB 90%
  ); 
}

.social-media-button:hover:nth-child(2) {
  background-color: var(--twitter);
}

.social-media-button:hover:nth-child(3) {
  background-color: var(--youtube);
}

.social-media-button:hover:nth-child(4) {
  background-color: var(--linkedin);
}

/* responsive media query start */
@media (max-width: 1104px) {
  .about .about-content .left img {
    height: 350px;
    width: 350px;
  }
}

@media (max-width: 991px) {
  .max-width {
    padding: 0px 0px;
  }
}
@media (max-width: 947px) {
  .column,
  .contact .contact-content .column {
    width: 100%;
    margin-bottom: 35px;
  }
}

.left1 {
  display: flex;
  gap: 20px;
}

.proj {
  padding-bottom: 4%;
}
.pa {
  padding-bottom: 2%;
}

/* .btn{
    color: #f1f1f1;
    width: 120px!important;
    height: 45px;
    outline: none;
    font-size: 18px;
    font-weight: 500;
    border-radius: 6px;
    cursor: pointer;
    flex-wrap: nowrap;
    background:#f055ed;
    border: 2px solid #f055ed;
} */

.btn:hover {
  color: #111;
  background: none;
  background-color: rgb(108, 53, 53);
}
#logo {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 90px;
}

/* Followuson */

#Followuson {
  justify-content: space-between;
}

/* .App {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  border: 1px solid red;
} */

.port1 {
  width: 100%;
  /* background-color: #001f3f; */
  height: 100%;
  width: 100%;
  background: linear-gradient(45deg, #d2001a, #7462ff, #f482ff, #23d5ab);
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  /* background-image: url('./images/adharva-imgs/profile-bg.jpg'); */
  /* border: 1px solid red; */
  /* background: #004AAD;  */
  /* background: -webkit-linear-gradient(to right, #fc17fa, #004AAD);
background: linear-gradient(to right, #fc17fa, #004AAD); */
  /* background: #373B44; 
background: -webkit-linear-gradient(to right, #4286f4, #373B44); 
background: linear-gradient(to right, #4286f4, #373B44); */
  /* background: #667db6; */
  /* background: -webkit-linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6);
background: linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6);  */
}

@keyframes color {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}
.user {
  width: 100%;
  border: 2px solid black;
  border-radius: 10px;
  background-color: #b3e3ff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 500px;
  height: 300px;
  margin: 20px;
}

.paginationBttns {
  width: 100%;
  height: 30px;
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 100px 0px;
}

.paginationBttns a {
  padding: 16px 20px;
  margin: 8px;
  border-radius: 50%;
  border: 1px solid #fff;
  color: blue;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: white;
  background-color: #f055ed;
}

.paginationActive a {
  color: white;
  background-color: #004AAD;
}

.paginationDisabled a {
  color: #f055ed;
  background-color: #f055ed;
  display: none;
}

/* -------scroll-up---------- */

.scroll-up-btn {
  position: fixed;
  height: 45px;
  width: 42px;
  background: #d9a53d;
  right: 30px;
  bottom: 10px;
  text-align: center;
  line-height: 45px;
  color: #fff;
  z-index: 9999;
  font-size: 30px;
  border-radius: 6px;
  border-bottom-width: 2px;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
}
.scroll-up-btn.show {
  bottom: 30px;
  opacity: 1;
  pointer-events: auto;
}
.scroll-up-btn:hover {
  filter: brightness(90%);
}

.scroll-to-top {
  width: 50px !important;
  height: 50px !important;
}

/* -----------whatsapp------------ */

/* .react-floating-whatsapp-button-left {
  right: auto !important;
  left: 20px !important;
} */

.styles-module_whatsappButton__tVits {
  left: 2rem !important;
  bottom: 2.5rem !important;
}

.styles-module_whatsappChatBox__MdQ5U {
  left: 4rem !important;
}

/* ------------------- */

.modal-90w {
  /* background-color: blanchedalmond; */
  /* position: fixed; */
  padding: 50px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(45deg, #d2001a, #7462ff, #f482ff, #23d5ab); */
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
}

/* .book-pdf{
  background-color: red;
} */

/* @media all and (min-width: 320px) and (max-width:480px){
  .modal-90w{
    /* background-color: blanchedalmond; */
/* position: fixed; */
/* padding: 20px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-width: 0;
    min-height: 0;
    width: 95%; */
/* margin: 50px; */
/* margin: auto; */
/* background: linear-gradient(45deg, #d2001a, #7462ff, #f482ff, #23d5ab); */
/* background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  } */

/* .HTMLFlipBook{
    width: 100%;
    padding: 20%;
  }
} */

/* @media screen and (max-width:767px){
  .modal-90w{
    padding: 20px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-width: 0;
    min-height: 0;
    width: 95%;
    margin: auto;
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  }

  .HTMLFlipBook{
    width: 100%;
    padding: 20%;
  }
} */

.use-chat-gpt-ai-emotion-cache-11tnanq div {
  user-select: none !important;
}

/* .css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-left: 8px !important;
} */

.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-left: 0px !important;
}

.pdf-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.flipbook {
  width: 100%;
  height: 100%;
}

/* @media all and (min-width: 320px) and (max-width:480px){
  .faq-smallscreens{
    background-color: red;
    font-size: 10px;
  }
} */

.faq-answer {
  font-size: 16px;
}

.faq-answer {
  font-size: 16px;
}

/* Small screens */
@media (max-width: 576px) {

  /* .slideshow-main-div {
    margin: auto;
    align-items: center;
    justify-content: "center";
    position: relative;
    background-color: #fff;
    height: 240px;
    width: 100%;
    overflow: hidden;
  } */

  .faq-answer {
    font-size: 14px;
  }
}

/* Medium screens */
@media (min-width: 577px) and (max-width: 992px) {
  .faq-answer {
    font-size: 16px;
  }
}

/* Large screens */
@media (min-width: 993px) {
  .faq-answer {
    font-size: 18px;
  }
}


/* profile comment section */

.commentlike-section{
  display: flex;
  width: 50%;
  margin: auto;
  border-radius: 20px 50px;
  gap: 30px;
  
}

.commentlike-section img{
  width: 30%;
}

.like-button {
  background-color: #ffffff;
  /* background: transparent; */
  color: #ed4956;
  border: none;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 20px 50px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.comment-button {
  background-color: #ffffff;
  /* background: transparent; */
  color: #0095f6;
  border: none;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 20px 50px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.share-button {
  background-color: #ffffff;
  /* background: transparent; */
  color: #1877f2;
  border: none;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 20px 50px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.view-button {
  background-color: #ffffff;
  /* background: transparent; */
  color: #4267b2;
  border: none;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 20px 50px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.commentlike-section button:hover{
  width: 110%;
  height: 110%;
  
}


.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: 20px !important;
}


/* ---------- */

@media all and (min-width: 320px) and (max-width: 480px) {
  .main-logo {
    width: 120px;
    height: auto;
  }

  .home-heading-sizes {
    font-size: 29px;
  }

  .slideshow-main-div {
    margin: auto;
    position: relative;
    background-color: #fff;
    height: 124px;
    width: 100%;
    overflow: hidden;
  }

  /* profile comment section */

.commentlike-section{
  display: flex;
  width: 80%;
  margin: auto;
  border-radius: 20px 50px;
  gap: 30px;
  
}

.commentlike-section img{
  width: 100%;
}

.container {
  
  width: 290px;
  height: 100%;
  border-radius: 20px;
  padding: 50px;
  box-sizing: border-box;
  background: #ecf0f3;
  box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
}

}

@media all and (min-width: 481px) and (max-width: 768px) {
  .main-logo {
    width: 180px;
    height: auto;
  }

  .home-heading-sizes {
    font-size: 30px;
  }

  .slideshow-main-div {
    margin: auto;
    position: relative;
    background-color: #fff;
    height: 185px;
    width: 100%;
    overflow: hidden;
  }

  /* profile comment section */

.commentlike-section{
  display: flex;
  width: 80%;
  margin: auto;
  border-radius: 20px 50px;
  gap: 20px;
  
}

.commentlike-section img{
  width: 70%;
}

}

@media all and (min-width: 769px) and (max-width: 1180px) {
  .main-logo {
    width: 230px;
    height: auto;
  }

  .home-heading-sizes {
    font-size: 33px;
  }

  .slideshow-main-div {
    margin: auto;
    position: relative;
    background-color: #fff;
    height: 300px;
    width: 100%;
    overflow: hidden;
  }
  /* profile comment section */

.commentlike-section{
  display: flex;
  width: 80%;
  margin: auto;
  border-radius: 20px 50px;
  gap: 30px;
  
}

.commentlike-section img{
  width: 50%;
}
}

@media all and (min-width: 1181px) and (max-width: 1920px) {
  .main-logo {
    width: 230px;
    height: auto;
  }

  .home-heading-sizes {
    font-size: 33px;
  }

  .slideshow-main-div {
    margin: auto;
    position: relative;
    background-color: #fff;
    height: 500px;
    width: 100%;
    overflow: hidden;
  }
}

@media all and (min-width: 1921px) and (max-width: 2420px) {
  .main-logo {
    width: 230px;
    height: auto;
  }

  .home-heading-sizes {
    font-size: 33px;
  }

  .slideshow-main-div {
    margin: auto;
    position: relative;
    background-color: #fff;
    height: 650px;
    width: 100%;
    overflow: hidden;
  }
}


.my-flex-container {
  justify-content: flex-end !important; /* No warning */
}


/* test flip  */

/* Flipbook.css */

/* Main container for the flipbook */
.flipbook-container {
  width: 80%;
  margin: auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background: linear-gradient(45deg, #d2001a, #7462ff, #f482ff, #23d5ab); */
  background-size: 300% 300%;
  /* animation: color 12s ease-in-out infinite; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Default appearance for flipbook */
.flipbook {
  width: 100%;
  max-width: 100%;
}

/* Media Queries for various screen sizes */

/* Large screens (desktop) */
@media (min-width: 1024px) {
  .flipbook-container {
    width: 60%;
    padding: 30px;
  }
}

/* Medium screens (tablet) */
@media (max-width: 1024px) {
  .flipbook-container {
    width: 70%;
    padding: 20px;
  }

  .flipbook {
    width: 90%;
    max-width: 90%;
  }
}

/* Small screens (mobile) */
@media (max-width: 768px) {
  .flipbook-container {
    width: 90%;
    padding: 10px;
    height: 100%;
  }

  .flipbook {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

/* Extra small screens (mobile portrait) */
@media (max-width: 480px) {
  .flipbook-container {
    width: 100%;
    padding: 5px;
    height: 100%;
  }

  .flipbook {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

/* Background color animation */
/* @keyframes color {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
} */
